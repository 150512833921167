const envs = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  extensionUrl: process.env.REACT_APP_EXTENSION_URL,
  extensionId: process.env.REACT_APP_BLOCKEM_EXTENSION_ID,
  blockemWebsiteUrl: process.env.REACT_APP_BLOCKEM_WEBSITE_URL,
  ck: process.env.REACT_APP_CK,
  ktm: process.env.REACT_APP_KTM,
  alg: process.env.REACT_APP_ALG,
  infuraApiKey: process.env.REACT_APP_INFURA_KEY,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  hotjar: {
    id: process.env.REACT_APP_HOTJAR_ID,
  },
  contactEmail: 'info@blockem.io',
  social: {
    twitter: process.env.REACT_APP_BLOCKEM_TWITTER_URL,
    discord: process.env.REACT_APP_BLOCKEM_DISCORD_URL,
    telegram: process.env.REACT_APP_BLOCKEM_TELEGRAM_URL,
    linkedin: process.env.REACT_APP_AIRLIUS_LINKEDIN_URL,
  },
  google: {
    analyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  },
};

export { envs };
