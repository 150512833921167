import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDocumentTitle } from '../../hooks/useDocumentTitle';

import { Icon } from '../../assets';
import { ButtonSize } from '../../components/ui/buttons';
// @ts-ignore;

import Styled from '../../components/ui/other/TextOnlyPageUiComponents';

export const PrivacyPolicyPage: React.FC = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('common.privacyPolicy'));

  return (
    <Styled.Container>
      <Styled.Title>{t('common.privacyPolicy')}</Styled.Title>
      <Styled.LastUpdated>Effective date: 08/01/2022</Styled.LastUpdated>
      <Styled.Section>
        <Styled.SectionTitle>1. Introduction</Styled.SectionTitle>
        <Styled.Text>
          Welcome to <strong>Blockem Inc</strong>
        </Styled.Text>
        <Styled.Text>
          Blockem Inc (“us”, “we”, or “our”) operates blockem.io application (hereinafter
          referred to as <strong>“Service”</strong>).
        </Styled.Text>
        <Styled.Text>
          Our Privacy Policy governs your visit to blockem.io and blockem.io mobile
          application, and explains how we collect, safeguard and disclose information
          that results from your use of our Service
        </Styled.Text>
        <Styled.Text>
          We use your data to provide and improve Service. By using Service, you agree to
          the collection and use of information in accordance with this policy. Unless
          otherwise defined in this Privacy Policy, the terms used in this Privacy Policy
          have the same meanings as in our Terms and Conditions.
        </Styled.Text>
        <Styled.Text>
          Our Terms and Conditions <strong>(“Terms”)</strong> govern all use of our
          Service and together with the Privacy Policy constitutes your agreement with us{' '}
          <strong>(“agreement”)</strong>.
        </Styled.Text>
      </Styled.Section>

      <Styled.Section>
        <Styled.SectionTitle>2. Definitions</Styled.SectionTitle>
        <Styled.Text>
          <strong>SERVICE</strong> means the blockem.io website and blockem.io mobile
          application operated by Blockem Inc
          <br />
          <strong>PERSONAL DATA</strong> means data about a living individual who can be
          identified from those data (or from those and other information either in our
          possession or likely to come into our possession).
          <br />
          <strong>USAGE DATA</strong> is data collected automatically either generated by
          the use of Service or from Service infrastructure itself (for example, the
          duration of a page visit).
          <br />
          <strong>COOKIES</strong> are small files stored on your device (computer or
          mobile device).
          <br />
          <strong>DATA CONTROLLER</strong> means a natural or legal person who (either
          alone or jointly or in common with other persons) determines the purposes for
          which and the manner in which any personal data are, or are to be, processed.
          For the purpose of this Privacy Policy, we are a Data Controller of your data.
          <br />
          <strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means any natural or
          legal person who processes the data on behalf of the Data Controller. We may use
          the services of various Service Providers in order to process your data more
          effectively.
          <br />
          <strong>DATA SUBJECT</strong> is any living individual who is the subject of
          Personal Data.
          <br />
          <strong>THE USER</strong> is the individual using our Service. The User
          corresponds to the Data Subject, who is the subject of Personal Data.
        </Styled.Text>
      </Styled.Section>

      <Styled.Section>
        <Styled.SectionTitle>3. Information Collection and Use</Styled.SectionTitle>
        <Styled.Text>
          We collect several different types of information for various purposes to
          provide and improve our Service to you.
        </Styled.Text>
      </Styled.Section>

      <Styled.Section>
        <Styled.SectionTitle>4. Types of Data Collected</Styled.SectionTitle>

        <Styled.InnerSection>
          <Styled.InnerSectionTitle>Personal Data</Styled.InnerSectionTitle>
          <Styled.Text>
            While using our Service, we may ask you to provide us with certain personally
            identifiable information that can be used to contact or identify you
            <strong> (“Personal Data”) </strong>. Personally identifiable information may
            include, but is not limited to:
          </Styled.Text>
          <Styled.OL>
            <li>Email address</li>
            <li>Cookies and Usage Data</li>
          </Styled.OL>
          <Styled.Text>
            We may use your Personal Data to contact you with newsletters, marketing or
            promotional materials and other information that may be of interest to you.
            You may opt out of receiving any, or all, of these communications from us by
            emailing at <a href='mailto:info@blockem.io'>info@blockem.io</a>.
          </Styled.Text>
        </Styled.InnerSection>

        <Styled.InnerSection>
          <Styled.InnerSectionTitle>Tracking Cookies Data</Styled.InnerSectionTitle>
          <Styled.Text>
            We use cookies and similar tracking technologies to track the activity on our
            Service and we hold certain information.
          </Styled.Text>
          <Styled.Text>
            Cookies are files with a small amount of data which may include an anonymous
            unique identifier. Cookies are sent to your browser from a website and stored
            on your device. Other tracking technologies are also used such as beacons,
            tags and scripts to collect and track information and to improve and analyze
            our Service.
          </Styled.Text>
          <Styled.Text>
            You can instruct your browser to refuse all cookies or to indicate when a
            cookie is being sent. However, if you do not accept cookies, you may not be
            able to use some portions of our Service.
          </Styled.Text>
          <Styled.Text>Examples of Cookies we use:</Styled.Text>
          <Styled.OL>
            <li>
              <strong>Session Cookies:</strong> We use Session Cookies to operate our
              Service.
            </li>
            <li>
              <strong>Preference Cookies:</strong> We use Preference Cookies to remember
              your preferences and various settings.
            </li>
            <li>
              <strong>Security Cookies:</strong> We use Security Cookies for security
              purposes.
            </li>
            <li>
              <strong>Advertising Cookies:</strong> Advertising Cookies are used to serve
              you with advertisements that may be relevant to you and your interests.
            </li>
          </Styled.OL>
        </Styled.InnerSection>

        <Styled.InnerSection>
          <Styled.InnerSectionTitle>Other Data</Styled.InnerSectionTitle>
          <Styled.Text>
            While using our Service, we may also collect the following information: wallet
            address, transactions you did on a blockchain and other blockchain-related
            data.
          </Styled.Text>
        </Styled.InnerSection>
      </Styled.Section>

      <Styled.Section>
        <Styled.SectionTitle>5. Use of Data</Styled.SectionTitle>
        <Styled.Text>
          Blockem Inc uses the collected data for various purposes:
        </Styled.Text>
        <Styled.OL>
          <li>to provide and maintain our Service</li>
          <li>to notify you about changes to our Service</li>
          <li>
            to allow you to participate in interactive features of our Service when you
            choose to do so
          </li>
          <li>to provide customer support</li>
          <li>
            to gather analysis or valuable information so that we can improve our Service
          </li>
          <li>to monitor the usage of our Service</li>
          <li>to detect, prevent and address technical issues</li>
          <li>to fulfill any other purpose for which you provide it</li>
          <li>
            to carry out our obligations and enforce our rights arising from any contracts
            entered into between you and us, including for billing and collection
          </li>
          <li>
            to provide you with notices about your account and/or subscription, including
            expiration and renewal notices, email-instructions, etc.
          </li>
          <li>
            to provide you with news, special offers and general information about other
            goods, services and events which we offer that are similar to those that you
            have already purchased or enquired about unless you have opted not to receive
            such information
          </li>
          <li>in any other way we may describe when you provide the information</li>
          <li>for any other purpose with your consent</li>
        </Styled.OL>
      </Styled.Section>

      <Styled.Section>
        <Styled.SectionTitle>6. Retention of Data</Styled.SectionTitle>
        <Styled.Text>
          We will retain your Personal Data only for as long as is necessary for the
          purposes set out in this Privacy Policy. We will retain and use your Personal
          Data to the extent necessary to comply with our legal obligations (for example,
          if we are required to retain your data to comply with applicable laws), resolve
          disputes, and enforce our legal agreements and policies.
        </Styled.Text>
        <Styled.Text>
          We will also retain Usage Data for internal analysis purposes. Usage Data is
          generally retained for a shorter period, except when this data is used to
          strengthen the security or to improve the functionality of our Service, or we
          are legally obligated to retain this data for longer time periods.
        </Styled.Text>
      </Styled.Section>

      <Styled.Section>
        <Styled.SectionTitle>7. Transfer of Data</Styled.SectionTitle>
        <Styled.Text>
          Your information, including Personal Data, may be transferred to – and
          maintained on – computers located outside of your state, province, country or
          other governmental jurisdiction where the data protection laws may differ from
          those of your jurisdiction.
        </Styled.Text>
        <Styled.Text>
          If you are located outside United States and choose to provide information to
          us, please note that we transfer the data, including Personal Data, to United
          States and process it there.
        </Styled.Text>
        <Styled.Text>
          Your consent to this Privacy Policy followed by your submission of such
          information represents your agreement to that transfer.
        </Styled.Text>
        <Styled.Text>
          Blockem Inc will take all the steps reasonably necessary to ensure that your
          data is treated securely and in accordance with this Privacy Policy and no
          transfer of your Personal Data will take place to an organisation or a country
          unless there are adequate controls in place including the security of your data
          and other personal information.
        </Styled.Text>
      </Styled.Section>

      <Styled.Section>
        <Styled.SectionTitle>8. Disclosure of Data</Styled.SectionTitle>
        <Styled.Text>
          We may disclose personal information that we collect, or you provide:
        </Styled.Text>
        <Styled.OL>
          <li>
            <strong>Disclosure for Law Enforcement.</strong>
            <br />
            Under certain circumstances, we may be required to disclose your Personal Data
            if required to do so by law or in response to valid requests by public
            authorities.
          </li>
          <li>
            <strong>Business Transaction.</strong>
            <br />
            If we or our subsidiaries are involved in a merger, acquisition or asset sale,
            your Personal Data may be transferred.
          </li>
          <li>
            Other cases. We may disclose your information also:
            <br />
            <Styled.OL type='i'>
              <li>to our subsidiaries and affiliates</li>
              <li>
                to contractors, service providers, and other third parties we use to
                support our business
              </li>
              <li>to fulfill the purpose for which you provide it</li>
              <li>with your consent in any other cases</li>
            </Styled.OL>
          </li>
        </Styled.OL>
      </Styled.Section>

      <Styled.Section>
        <Styled.SectionTitle>9. Security of Data</Styled.SectionTitle>
        <Styled.Text>
          The security of your data is important to us but remember that no method of
          transmission over the Internet or method of electronic storage is 100% secure.
          While we strive to use commercially acceptable means to protect your Personal
          Data, we cannot guarantee its absolute security.
        </Styled.Text>
      </Styled.Section>

      <Styled.Section>
        <Styled.SectionTitle>
          10. Your Data Protection Rights Under General Data Protection Regulation (GDPR)
        </Styled.SectionTitle>
        <Styled.Text>
          If you are a resident of the European Union (EU) and European Economic Area
          (EEA), you have certain data protection rights, covered by GDPR. – See more at{' '}
          <a href='https://eur-lex.europa.eu/eli/reg/2016/679/oj'>here</a>.
        </Styled.Text>
        <Styled.Text>
          We aim to take reasonable steps to allow you to correct, amend, delete, or limit
          the use of your Personal Data.
        </Styled.Text>
        <Styled.Text>
          If you wish to be informed what Personal Data we hold about you and if you want
          it to be removed from our systems, please email us at{' '}
          <a href='mailto:info@blockem.io'>info@blockem.io</a>.
        </Styled.Text>
        <Styled.Text>
          In certain circumstances, you have the following data protection rights:
        </Styled.Text>
        <Styled.OL>
          <li>the right to access, update or to delete the information we have on you</li>
          <li>
            the right of rectification. You have the right to have your information
            rectified if that information is inaccurate or incomplete
          </li>
          <li>
            the right to object. You have the right to object to our processing of your
            Personal Data
          </li>
          <li>
            he right of restriction. You have the right to request that we restrict the
            processing of your personal information
          </li>
          <li>
            the right to data portability. You have the right to be provided with a copy
            of your Personal Data in a structured, machine-readable and commonly used
            format
          </li>
          <li>
            the right to withdraw consent. You also have the right to withdraw your
            consent at any time where we rely on your consent to process your personal
            information
          </li>
        </Styled.OL>
        <Styled.Text>
          Please note that we may ask you to verify your identity before responding to
          such requests. Please note, we may not able to provide Service without some
          necessary data.
        </Styled.Text>
        <Styled.Text>
          You have the right to complain to a Data Protection Authority about our
          collection and use of your Personal Data. For more information, please contact
          your local data protection authority in the European Economic Area (EEA).
        </Styled.Text>
      </Styled.Section>

      <Styled.Section>
        <Styled.SectionTitle>
          11. Your Data Protection Rights under the California Privacy Protection Act
          (CalOPPA)
        </Styled.SectionTitle>
        <Styled.Text>
          CalOPPA is the first state law in the nation to require commercial websites and
          online services to post a privacy policy. The law’s reach stretches well beyond
          California to require a person or company in the United States (and conceivable
          the world) that operates websites collecting personally identifiable information
          from California consumers to post a conspicuous privacy policy on its website
          stating exactly the information being collected and 5 those individuals with
          whom it is being shared, and to comply with this policy. – See more{' '}
          <a href='https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protecti on-act-caloppa-3/'>
            here
          </a>
        </Styled.Text>
        <Styled.Text>According to CalOPPA we agree to the following:</Styled.Text>
        <Styled.OL>
          <li>users can visit our site anonymously</li>
          <li>
            our Privacy Policy link includes the word “Privacy”, and can easily be found
            on the page specified above on the home page of our website
          </li>
          <li>
            users will be notified of any privacy policy changes on our Privacy Policy
            Page
          </li>
          <li>
            users are able to change their personal information by emailing us at{' '}
            <a href='mailto:info@blockem.io'>info@blockem.io</a>.
          </li>
        </Styled.OL>
        <Styled.Text>Our Policy on “Do Not Track” Signals:</Styled.Text>
        <Styled.Text>
          We honor Do Not Track signals and do not track, plant cookies, or use
          advertising when a Do Not Track browser mechanism is in place. Do Not Track is a
          preference you can set in your web browser to inform websites that you do not
          want to be tracked.
        </Styled.Text>
        <Styled.Text>
          You can enable or disable Do Not Track by visiting the Preferences or Settings
          page of your web browser.
        </Styled.Text>
      </Styled.Section>

      <Styled.Section>
        <Styled.SectionTitle>12. Service Providers</Styled.SectionTitle>
        <Styled.Text>
          We may employ third party companies and individuals to facilitate our Service
          <strong>(“Service Providers”)</strong>, provide Service on our behalf, perform
          Service-related services or assist us in analysing how our Service is used.
        </Styled.Text>
        <Styled.Text>
          These third parties have access to your Personal Data only to perform these
          tasks on our behalf and are obligated not to disclose or use it for any other
          purpose.
        </Styled.Text>
      </Styled.Section>

      <Styled.Section>
        <Styled.SectionTitle>13. Analytics</Styled.SectionTitle>
        <Styled.Text>
          We may use third-party Service Providers to monitor and analyze the use of our
          Service.
        </Styled.Text>
        <Styled.Text>
          <strong>Google Analytics</strong>
          <br />
          Google Analytics is a web analytics service offered by Google that tracks and
          reports website traffic. Google uses the data collected to track and monitor the
          use of our Service. This data is shared with other Google services. Google may
          use the collected data to contextualise and personalise the ads of its own
          advertising network.
        </Styled.Text>
        <Styled.Text>
          For more information on the privacy practices of Google, please visit the{' '}
          <a href='https://policies.google.com/privacy?hl=en'>Google Privacy Terms</a> web
          page.
        </Styled.Text>
        <Styled.Text>
          We also encourage you to review the Google's policy for safeguarding your data{' '}
          <a href='https://support.google.com/analytics/answer/6004245'>here</a>.
        </Styled.Text>
      </Styled.Section>
      {/*<Styled.Section>*/}
      {/*  <Styled.DownloadButton onClick={() => window.open(pdf)} size={ButtonSize.Lg}>*/}
      {/*    {t('common.download', { format: 'PDF' })}*/}
      {/*    <Icon.Download />*/}
      {/*  </Styled.DownloadButton>*/}
      {/*</Styled.Section>*/}
    </Styled.Container>
  );
};
